import _default from "./default";
import _css from "./css";
var exports = {};

/**
 * cssfilter
 *
 * @author 老雷<leizongmin@gmail.com>
 */
var DEFAULT = _default;
var FilterCSS = _css;
/**
 * XSS过滤
 *
 * @param {String} css 要过滤的CSS代码
 * @param {Object} options 选项：whiteList, onAttr, onIgnoreAttr
 * @return {String}
 */

function filterCSS(html, options) {
  var xss = new FilterCSS(options);
  return xss.process(html);
} // 输出


exports = exports = filterCSS;
exports.FilterCSS = FilterCSS;

for (var i in DEFAULT) exports[i] = DEFAULT[i]; // 在浏览器端使用


if (typeof window !== "undefined") {
  window.filterCSS = exports;
}

export default exports;
const _FilterCSS = exports.FilterCSS;
export { _FilterCSS as FilterCSS };