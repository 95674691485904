import _util from "./util";
var exports = {};

/**
 * cssfilter
 *
 * @author 老雷<leizongmin@gmail.com>
 */
var _ = _util;
/**
 * 解析style
 *
 * @param {String} css
 * @param {Function} onAttr 处理属性的函数
 *   参数格式： function (sourcePosition, position, name, value, source)
 * @return {String}
 */

function parseStyle(css, onAttr) {
  css = _.trimRight(css);
  if (css[css.length - 1] !== ";") css += ";";
  var cssLength = css.length;
  var isParenthesisOpen = false;
  var lastPos = 0;
  var i = 0;
  var retCSS = "";

  function addNewAttr() {
    // 如果没有正常的闭合圆括号，则直接忽略当前属性
    if (!isParenthesisOpen) {
      var source = _.trim(css.slice(lastPos, i));

      var j = source.indexOf(":");

      if (j !== -1) {
        var name = _.trim(source.slice(0, j));

        var value = _.trim(source.slice(j + 1)); // 必须有属性名称


        if (name) {
          var ret = onAttr(lastPos, retCSS.length, name, value, source);
          if (ret) retCSS += ret + "; ";
        }
      }
    }

    lastPos = i + 1;
  }

  for (; i < cssLength; i++) {
    var c = css[i];

    if (c === "/" && css[i + 1] === "*") {
      // 备注开始
      var j = css.indexOf("*/", i + 2); // 如果没有正常的备注结束，则后面的部分全部跳过

      if (j === -1) break; // 直接将当前位置调到备注结尾，并且初始化状态

      i = j + 1;
      lastPos = i + 1;
      isParenthesisOpen = false;
    } else if (c === "(") {
      isParenthesisOpen = true;
    } else if (c === ")") {
      isParenthesisOpen = false;
    } else if (c === ";") {
      if (isParenthesisOpen) {// 在圆括号里面，忽略
      } else {
        addNewAttr();
      }
    } else if (c === "\n") {
      addNewAttr();
    }
  }

  return _.trim(retCSS);
}

exports = parseStyle;
export default exports;